import React from 'react';
import { Text } from 'wix-ui-tpa';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { st, classes } from './DayDetails.st.css';

export interface DayDetailsProps {
  date: string;
}
export const DayDetails: React.FC<DayDetailsProps> = ({ date }) => {
  const { isMobile } = useEnvironment();
  return (
    <div className={st(classes.root, { isMobile })}>
      <div>
        <Text className={classes.text} data-hook="date">
          {date}
        </Text>
      </div>
    </div>
  );
};
