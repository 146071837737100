import React from 'react';
import TpaModal from '../Modal/TpaModal';
import { Button, Text, TextTypography as TYPOGRAPHY } from 'wix-ui-tpa';
import { classes, st } from './UserMessage.st.css';

interface UserMessageProps {
  isOpen: boolean;
  onRequestClose: () => void;
  isMobile: boolean;
  isRTL: boolean;
  message: string;
  okLabel: string;
}

const UserMessage: React.FC<UserMessageProps> = ({
  isOpen,
  isMobile,
  isRTL,
  message,
  okLabel,
  onRequestClose,
}) => {
  return (
    <TpaModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      isMobile={isMobile}
      isRTL={isRTL}
    >
      <div className={st(classes.root)}>
        <Text
          className={st(classes.messageText)}
          typography={TYPOGRAPHY.runningText}
        >
          {message}
        </Text>
        <Button
          className={st(classes.messageButton)}
          onClick={() => onRequestClose()}
          data-hook="user-message-action-button"
          fullWidth={isMobile}
        >
          {okLabel}
        </Button>
      </div>
    </TpaModal>
  );
};

export default UserMessage;
