import React from 'react';
import { classes, st } from './SectionText.st.css';
import { Text } from 'wix-ui-tpa';
import { useRenderLocation } from '../useRenderLocation';
import { useEnvironment } from '@wix/yoshi-flow-editor';

interface SectionTitleProps {
  text?: string;
  dataHook?: string;
  className?: string;
  tagName?: string;
}

const SectionText: React.FC<SectionTitleProps> = ({
  text,
  className,
  dataHook = 'section-text',
  tagName = 'p',
  children,
}) => {
  const location = useRenderLocation();
  const { isMobile, isRTL } = useEnvironment();
  const content = text || children;
  return content ? (
    <Text
      data-hook={dataHook}
      className={st(classes.text, { location, isMobile, isRTL }, className)}
      tagName={tagName}
    >
      {content}
    </Text>
  ) : null;
};

export default SectionText;
